import React, { useEffect, useMemo } from "react";
import Card from "components/atoms/Card";
import Table from "modules/table";
import { useTrans } from "system/translations/hooks";
import { useTableColumns, useTableColumnsMovementAdjustmentDetails } from "modules/merchantsBalanceDetails/constantHooks";
import { generatePath, useParams } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import ButtonLink from "components/atoms/ButtonLink";
import { Add16, DocumentExport16, EventSchedule16 } from "components/atoms/IconsCreateFactory"
import { useClosePeriod, useGetMerchantBalanceDetailsAttachmentItem, useGetMerchantBalanceDetailsList, useGetMerchantsBalanceV2DetailsExport, useGetMerchantsBalanceV2DetailsTransactionExport, useUpdateManualTransactionItem } from "modules/merchantsBalanceDetails/apiHooks";
import StructuredList from "components/atoms/StructuredList";
import { useModal } from "modules/modal/hooks";
import TransactionDetails from "./components/TransactionDetails";
import { Button } from "carbon-components-react";
import { useFilters, useGetSaveFileOnDevice } from "system/helpers/hooks";
import { useGetActionsToClosePeriods, useGetPeriodsToCloseData, useModuleData } from "./hooks";
import config from "system/config";
import { AvailableMIMItypes } from "./types";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import Toggle from "components/atoms/Toggle";
import Loader from "components/atoms/Loader";


const MerchantsBalanceDetails = () => {
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const filterInitialValues = useMemo(
    () => ({
      fromDate: routeParams?.fromDate,
      toDate: routeParams?.toDate,
      isDirectCashIncluded: false,
    }),
    [routeParams]
  );
  const { filters, setFilters } = useFilters(filterInitialValues);
  const { showModal, hideModal } = useModal();
  const { data, isLoading } = useGetMerchantBalanceDetailsList(
    routeParams?.merchantId,
    routeParams?.currency,
    filters
  );
  const { mutate: onUpdate, isLoading: isLoadingUpdateManualTransaction } = useUpdateManualTransactionItem()
  const columns = useTableColumns(data);
  const columnsMovementAdjustmentDetails = useTableColumnsMovementAdjustmentDetails();
  const periodsToCloseData = useGetPeriodsToCloseData(data)
  const { firstDayOfOpenedPeriod } = useModuleData(routeParams?.merchantId, routeParams?.currency)
  const { mutate: onGetExport, isLoading: isLoadingExport } = useGetMerchantsBalanceV2DetailsExport()
  const { mutate: onGetDetailsTransactionExport, isLoading: isLoadingDetailsTransactionExport } = useGetMerchantsBalanceV2DetailsTransactionExport()
  const { mutateAsync: onGetAttachment , isLoading: isAttachmentLoading } = useGetMerchantBalanceDetailsAttachmentItem()
  const { saveFile } = useGetSaveFileOnDevice()

  const onExport = () => {
    onGetExport({
      merchantId: routeParams?.merchantId,
      currency: routeParams?.currency,
      filters: { ...filters }
    });
  };
  
  const onDetailsTransactionExport = ({ gate, type, fromDate, toDate }: any) => {
    onGetDetailsTransactionExport({
      merchantId: routeParams?.merchantId,
      currency: routeParams?.currency,
      filters: { 
        gate,
        type,
        fromDate,
        toDate,
        merchantId: routeParams?.merchantId,
        sendTime: new Date().getTime(),
        isDirectCashIncluded: filters?.isDirectCashIncluded
      }
    });
  };

  const showDetails = ({ gate, type, fromDate, toDate }: any) => {
    showModal(
      {
        componentProps: {
          initialFilters: {
            gate,
            type,
            fromDate,
            toDate
          },
          merchantId: routeParams.merchantId,
          currency: routeParams.currency
        },
        modalHeading: (
          <div className="modal-header__with-export-btn">
            {_t("transaction_list")}

            <Button
              kind="tertiary"
              renderIcon={DocumentExport16}
              onClick={() => onDetailsTransactionExport({ gate, type, fromDate, toDate })}
              disabled={isLoadingDetailsTransactionExport}
              >
                {_t("export_CSV")}
            </Button> 
          </div> 
        ),
        component: TransactionDetails,
        footer: Button,
        footerProps: {
          style: {
            display: 'none'
          }
        }
      }
    );
  };

    const { mutate: onClose } = useClosePeriod(
      {
        merchantId: routeParams.merchantId,
        id: periodsToCloseData?.[0]?.id as number,
        dateFrom: periodsToCloseData?.[0]?.dateFrom as string,
        dateTo: periodsToCloseData?.[0]?.dateTo as string
      }
      );

    const  { openClosedPeriod }  = useGetActionsToClosePeriods({
      periodsToCloseData,
      firstDayOfOpenedPeriod,
      filters,
      setFilters,
      onClose,
    })

    const onFieldDataUpdate = (data: any) => { 
      const normalizeData = {...data}
      normalizeData.data.transactionType = normalizeData.transactionType
      delete normalizeData.data.createdBy
      delete normalizeData.data.createdDate
      delete normalizeData.data.currency
      delete normalizeData.data.forDate

      if (normalizeData.data.operationType === "movementAdjustment" && normalizeData.data.adjustmentType === "decreaseBalance") {
        normalizeData.data.amount = +normalizeData.data.amount * -1
      }
      
      onUpdate(normalizeData)
    }

  const showMovementAdjustmentDetails = (data: any) => {
    showModal(
      {
        componentProps: {
          columns: columnsMovementAdjustmentDetails,
          data: data,
          cellProps: {
            onFieldDataUpdate,
            onGetAttachmentHandler
          }
        },
        modalHeading: _t('movements_ajustment_comment'),
        component: Table,
        footer: Button,
        footerProps: {
          style: {
            display: 'none'
          }
        }
      }, 'movementAdjustmentDetails'
    );
  }

  const onGetAttachmentHandler = async (data: any) =>  {
   const responseData = await onGetAttachment({
      id: data.id,
      type: data.operationType
    })
    const fileName = data.fileName.split('.')
    const fileType: AvailableMIMItypes = fileName[fileName.length - 1]

    saveFile({
      data: responseData,
      type: config.MIMItypes[fileType],
      fileName: fileName.slice(0, fileName.length - 1).join('')
    })
  }

  

  let columnData: any[] = [];
  let refIdx = 0;
  const balanceAdjustmentType = config.adjustmentType.balanceAdjustment.reduce((acc: any, item) => {
    acc[item.value] = item.label;
    return acc;
  }, {})
  if (data) {
    data?.singlePeriodResponses.map(item => {
      columnData = [
        ...columnData,
        { 
          ...item?.openingBalance,
          currency: item.totalGatesBalance.currency,
          movement: 'Opening balance',
          periodDateFrom: item.totalGatesBalance.dateFrom.split('-').join('/'),
          periodDateTo: item.totalGatesBalance.dateTo.split('-').join('/'),
          isClosed: item.totalGatesBalance.closed,
          refIdx
        },
         ...item.pspBalances,
         ...item?.merchantBalanceAdjustments?.map(item => {
          return {...item, movement: balanceAdjustmentType[item.balanceAdjustmentType]}
        }) || [],
         ...item.merchantTransfers.map(item => {
          return {...item, movement: 'Transfer'}
        }),
        {
          ...item.totalGatesBalance,
          movement: 'Total'
        }

      ]
      refIdx++
    })
  }

  const preparedDataStructuredList = {
    ...data?.summary,
    currency: data?.singlePeriodResponses[0].totalGatesBalance.currency
  }
  const hiddenColumnsbyDefault = ['onHoldAmount', 'rollingReserveReturnAmount', 'rollingReserveAmount', 'forDate', 'transferFee', 'createdBy', 'transferMethod']

  if (isLoading) {
    return <Loader formOverlay={true} /> 
  }

  return (
    <Card
      title={
        <div className="card__head__title--with-toggle">
          {data?.singlePeriodResponses[0].openingBalance.merchantName && _t("{merchant}_{balance}_{currency}", {
            merchant: data?.singlePeriodResponses[0].openingBalance.merchantName,
            balance: `${data?.summary.totalBalance}`,
            currency: `${data?.singlePeriodResponses[0].totalGatesBalance?.currency}`,
          })}
          <Toggle
            id="isDirectCashIncluded"
            labelText={_t("include_direct_cash")}
            value={filters?.isDirectCashIncluded}
            withControlledValue={true}
            onChange={(value) => {setFilters({...filters, isDirectCashIncluded: value})}} 
          />
        </div>
      }
      titleClassName={"card__head__title--with-toggle__container"}
      extra={
        <>
          <Button
            kind="tertiary"
            renderIcon={DocumentExport16}
            onClick={onExport}
            disabled={isLoadingExport}
            >
              {_t("export_CSV")}
          </Button>
          <RoleWrapper accessTo={"ROLE_MODIFY_MERCHANT_BALANCE_V2"}>
            <Button
              isExpressive
              kind='tertiary'
              renderIcon={EventSchedule16}
              onClick={openClosedPeriod}
              style={{color: '#252C32'}}
            >
              {_t("close_period")}
            </Button>
            <ButtonLink
              isExpressive
              renderIcon={Add16}
              linkTo={generatePath(APP_ROUTES.merchantsBalanceV2.detailsInCurrency.create, {
                merchantId: routeParams?.merchantId,
                currency: routeParams?.currency,
                fromDate: routeParams?.fromDate,
                toDate: routeParams?.toDate
              })}
              title={_t("manual_transaction")}
            />
          </RoleWrapper>
        </>
      }
      backLocation={{ pathname: '/merchants-balance-v2', state: { merchantId: [+routeParams?.merchantId], ...filterInitialValues, isBack: true } }}
    >
      <StructuredList data={preparedDataStructuredList} />
      <Table
        totalRecords={columnData.length}
        isColumnSettings={true}
        columns={columns}
        isLoading={isLoading || isLoadingUpdateManualTransaction}
        data={columnData || []}
        isPagination={false}
        hiddenColumnsbyDefault={hiddenColumnsbyDefault}
        tableName={"MerchantBalanceDetails"}
        cellProps={{
          showDetails,
          showMovementAdjustmentDetails,
          onFieldDataUpdate,
          onGetAttachmentHandler
        }}
      />
    </Card>
  );
};

export default MerchantsBalanceDetails;
